<template>
  <!--  min-width: 300px, please enforce in container-->
  <b-col class="mt-5">
    <div class="mt-2 mt-md-5 p-1">
      <v-select
        :filterable="false"
        :clearable="false"
        taggable
        :create-option="word => ({'code': { 'c': word, info: $t(' - gender app search')} })"
        :getOptionLabel="option => option.code.c"
        :options="autocompletionOptions"
        @input="newSearchTerm(searchTerm.code.c)"
        @search="setAutocompletionOptions"
        v-model="searchTerm">
        <template #option="{ code, label}">
          <div class="" style="font-size: medium;"> {{ code.c }}
            <span class="text-secondary"
                  v-if="code.info">
              {{code.info}}
            </span>
          </div>
          <div class="text-success" style="font-size: x-large;">{{ label }}</div>
        </template>
        <template slot="no-options">
          <div class="text-center w-100 h-100 p-2 rotate-text"
               v-if="autocompleteloading">{{$t('🕵‍♀🔍🕵')}}
          </div>
          <ul class="list-group list-group-horizontal-sm"
              v-if="!autocompleteloading">
              <!-- Empty field, the botton for new entries is in the list -->
          </ul>
        </template>
      </v-select>
    </div>
<div>
  <b-nav pills align="right" class="small mt-1 p-1" >
    <b-nav-item style="opacity: 80%" :active="dictionaryEntriesOrdering==='new'"  @click="changeDictionaryEntriesOrdering('new')"><i class="far fa-star "></i> <span class="small">{{ $t('new') }}</span></b-nav-item>
    <b-nav-item style="opacity: 80%" :active="dictionaryEntriesOrdering==='alpha'"  @click="changeDictionaryEntriesOrdering('alpha')"><i class=""></i> <span class="small">A → Z</span></b-nav-item>
    <b-nav-item style="opacity: 80%" :active="dictionaryEntriesOrdering==='omega'"  @click="changeDictionaryEntriesOrdering('omega')"><i class=""></i> <span class="small">Z → A</span></b-nav-item>
    <b-nav-item style="opacity: 80%" :active="dictionaryEntriesOrdering==='top'" @click="changeDictionaryEntriesOrdering('top')"><i class="fas fa-arrow-up "></i> <span class="small">top</span></b-nav-item>
  </b-nav>
</div>
        <div class="pt-3" v-if="neutralEntryInfo">
          <b-alert show dismissible variant="warning">
            {{neutralEntryInfo}}
          </b-alert>
        </div>
        <div class="pt-1" v-if="callToAction">
          <b-alert show dismissible variant="info">
            {{callToAction}}
          </b-alert>
        </div>
        <div class="text-center mt-5" v-if="loadedDictionaryEntries.length<=0 && isApiCalled"
            v-on="isLoggedIn() ? { click: () => add('') } : { click: () => showRegisterModal()}">
          <span class="btn-success p-2"> {{$t('Add Term')}}</span>
        </div>

  <b-col :key="data.id" class="mt-1 mb-md-4 p-1" v-for="data in loadedDictionaryEntries">
    <b-card
      border-variant="secondary"
      header-bg-variant="primary"
      :class="[muteCard(data) ? 'gender_card_muted': '', 'p-0 gender_card']"
    >
      <b-row>
        <b-col id="actions-left"
               class="col-1 col-sm-1 p-0">
          <ul class="list-group center-left" v-if="!muteCard(data)">
            <li class="list-group-item p-0 bg-transparent"
                :title="(data.like_by_me)?$t('this entry is useful (click again to undo)'):$t('this entry is useful')"
                 v-b-tooltip.hover.left
                v-if="!muteCard(data)"
                v-on="isLoggedIn() ? { click: () => like(data.id) } : { click: () => showRegisterModal()}">
              <i class="fa fa-angle-up fa-2x text-success custom-button" v-if="!data.like_by_me"></i>
              <i class="fa fa-caret-up fa-2x text-success custom-button" v-if="data.like_by_me"></i>
            </li>
            <li class="list-group-item m-0 p-0 text-center bg-transparent"
                v-if="!muteCard(data)">
              {{data.total_likes - data.total_dislikes}}
            </li>
            <li class="list-group-item p-0 bg-transparent"
                :title="(data.dislike_by_me)?$t('this entry is not useful (click again to undo)'):$t('this entry is not useful')"
                 v-b-tooltip.hover.left
                v-if="!muteCard(data)"
                v-on="isLoggedIn() ? { click: () => dislike(data.id) } : { click: () => showRegisterModal()}">
              <i class="fa fa-angle-down fa-2x text-secondary custom-button" v-if="!data.dislike_by_me"></i>
              <i class="fa fa-caret-down fa-2x text-secondary custom-button" v-if="data.dislike_by_me"></i>
            </li>
          </ul>
          <ul class="list-group center-left" v-if="muteCard(data)">
            <div style="user-select: none; transform: rotate(270deg);"
                 class="table-light p-1 rounded small mt-2 mb-2 text-muted"
                 v-if="muteCard(data) && (data.alternative || data.singular_gender_neutral || data.plural_gender_neutral)"
                 :title="$t(`Using the ${data.type}form is always an option`)"
                 v-b-tooltip.hover.left
            > {{ $t(shortFormLabel(data.type, shortformPlaceholder)) }}
            </div>
          </ul>
        </b-col>
        <b-col id="content"
               class="col-10 col-sm-10 p-2">
          <b-card-header
            class="p-0 p-sm-1 table-primary invisible-scrollbar overflow-auto border please-scroll">
            <ul class="list-group list-group-horizontal">
              <li
                :class="[containsSearchTerm( [data.term, data.alternative] ) ? 'active': '', 'btn btn-secondary small p-1 p-sm-2 m-1 m-sm-2']"
                v-if="data.term">
                <router-link :to="encodeURI('/dict/'+data.term)" replace class="spellchecker-underline">
                  {{data.term}}
                </router-link>
              </li>
              <li
                :class="[containsSearchTerm( [data.singular_masculine, data.singular_gender_neutral] ) ? 'active': '', 'btn btn-secondary small p-1 p-sm-2 m-1 m-sm-2']"
                v-if="data.singular_masculine">
                <router-link :to="encodeURI('/dict/'+ data.singular_masculine)" replace class="spellchecker-underline">
                  {{data.singular_masculine}}
                </router-link>
              </li>
              <li
                :class="[containsSearchTerm( [data.singular_feminine, data.singular_gender_neutral] ) ? 'active': '', 'btn btn-secondary small p-1 p-sm-2 m-1 m-sm-2']"
                v-if="data.singular_feminine">
                <router-link :to="encodeURI('/dict/'+ data.singular_feminine)" replace class=" spellchecker-underline">
                  {{data.singular_feminine}}
                </router-link>
              </li>
              <li
                :class="[containsSearchTerm( [data.plural_masculine, data.plural_gender_neutral] ) ? 'active': '', 'btn btn-secondary small p-1 p-sm-2 m-1 m-sm-2']"
                v-if="data.plural_masculine">
                <router-link :to="encodeURI('/dict/'+ data.plural_masculine)" replace class="spellchecker-underline">
                  {{data.plural_masculine}}
                </router-link>
              </li>
              <li
                :class="[containsSearchTerm( [data.plural_feminine, data.plural_gender_neutral]) ? 'active': '', 'btn btn-secondary small p-1 p-sm-2 m-1 m-sm-2']"
                v-if="data.plural_feminine">
                <router-link :to="encodeURI('/dict/'+ data.plural_feminine)" replace class="spellchecker-underline">
                  {{data.plural_feminine}}
                </router-link>
              </li>
            </ul>
          </b-card-header>
          <b-card-body
            class="p-1 p-md-2">
            <ul class="table p-0 m-0">
              <li id="alternative"
                  :class="[containsSearchTerm( [data.term, data.alternative] ) ? 'active table-success':'','invisible-scrollbar please-scroll p-1 p-sm-2 pl-0 pr-0']"
                  v-if="data.alternative && !muteCard(data)"
              >
                <i class="pl-1  pl-md-3 text-secondary text-center small text-muted col-1 fas fa-circle"></i>
                {{data.alternative}}
                <i
                  v-if="data.is_verified"
                  :class="[containsSearchTerm( [data.term, data.alternative] ) ? 'active':'','fas fa-check-circle fa-xs text-success']">
                </i>
                <i
                  v-if="!data.is_verified"
                  :class="[containsSearchTerm( [data.term, data.alternative] ) ? 'active':'','fas fa-question-circle fa-xs text-secondary']">
                </i>
              </li>
              <li :id="'singular_gender_neutral-' + data.singular_gender_neutral"
                  :class="[containsSearchTerm( [data.singular_masculine, data.singular_feminine, data.singular_gender_neutral] ) ? 'active table-success':'','invisible-scrollbar please-scroll p-1 p-sm-2 pl-0 pr-0']"
                  v-if="data.singular_gender_neutral && !muteCard(data)"
              >
                <span :class="[(data.singular_gender_neutral.endsWith(' Person')||'') ? 'text-muted':'']">
                <i class="pl-1  pl-md-3 text-secondary text-center small text-muted col-1 fas fa-user"></i>
                  {{data.singular_gender_neutral}}
                  </span>
                <i
                  v-if="data.is_verified"
                  :class="[containsSearchTerm( [data.singular_masculine, data.singular_feminine, data.singular_gender_neutral] ) ? 'active':'','fas fa-check-circle fa-xs text-success']">
                </i>
                <i
                  v-if="!data.is_verified"
                  :class="[containsSearchTerm( [data.singular_masculine, data.singular_feminine, data.singular_gender_neutral] ) ? 'active':'','fas fa-question-circle fa-xs text-secondary']">
                </i>
              </li>
              <li :id="'plural_gender_neutral-' + data.plural_gender_neutral"
                  :class="[containsSearchTerm( [data.plural_masculine, data.plural_feminine, data.plural_gender_neutral] ) ? 'active table-success':'','invisible-scrollbar please-scroll p-1 p-sm-2 pl-0 pr-0']"
                  v-if="data.plural_gender_neutral && !muteCard(data)"
              >
                <i class="pl-1 pl-md-3 text-secondary text-center small text-muted col-1 fas fa-user-friends"></i>
                {{data.plural_gender_neutral}}
                <i
                  v-if="data.is_verified"
                  :class="[containsSearchTerm( [data.plural_masculine, data.plural_feminine, data.plural_gender_neutral] ) ? 'active':'','fas fa-check-circle fa-xs text-success']">
                </i>
                <i
                  v-if="!data.is_verified"
                  :class="[containsSearchTerm( [data.plural_masculine, data.plural_feminine, data.plural_gender_neutral] ) ? 'active':'','fas fa-question-circle fa-xs text-secondary']">
                </i>
              </li>
              <li :id="'term_muted_form-' + data.alternative"
                  :class="[containsSearchTerm( [data.term, data.alternative] ) ? 'active table-success':'','invisible-scrollbar please-scroll p-1 p-sm-2 pl-0 pr-0 small text-muted']"
                  v-if="muteCard(data) && data.alternative">
                <i class="pl-1  pl-md-3 text-secondary text-center small text-muted col-1 fas fa-circle"></i>
                {{ fixShortformPlaceholder(data.alternative) }}
                <i
                  v-if="data.is_verified"
                  :class="[containsSearchTerm( [data.term, data.alternative] ) ? 'active':'','fas fa-check-circle fa-xs text-success']">
                </i>
                <i
                  v-if="!data.is_verified"
                  :class="[containsSearchTerm( [data.term, data.alternative] ) ? 'active':'','fas fa-question-circle fa-xs text-secondary']">
                </i>
              </li>
              <li :id="'singular_muted_form-' + data.singular_gender_neutral"
                  :class="[containsSearchTerm( [data.singular_masculine, data.singular_feminine, data.singular_gender_neutral] ) ? 'active table-success':'','invisible-scrollbar please-scroll p-1 p-sm-2 pl-0 pr-0 small text-muted']"
                  v-if="muteCard(data) && data.singular_gender_neutral">
                <i class="pl-1  pl-md-3 text-secondary text-center small text-muted col-1 fas fa-user"></i>
                {{ fixShortformPlaceholder(data.singular_gender_neutral) }}
                <i
                  v-if="data.is_verified"
                  :class="[containsSearchTerm( [data.singular_masculine, data.singular_feminine, data.singular_gender_neutral] ) ? 'active':'','fas fa-check-circle fa-xs text-success']">
                </i>
                <i
                  v-if="!data.is_verified"
                  :class="[containsSearchTerm( [data.singular_masculine, data.singular_feminine, data.singular_gender_neutral] ) ? 'active':'','fas fa-question-circle fa-xs text-secondary']">
                </i>
              </li>
              <li :id="'plural_muted_form-' + data.singular_gender_neutral"
                  :class="[containsSearchTerm( [data.plural_masculine, data.plural_feminine, data.plural_gender_neutral] ) ? 'active table-success':'','invisible-scrollbar please-scroll p-1 p-sm-2 pl-0 pr-0 small text-muted']"
                  v-if="muteCard(data) && data.plural_gender_neutral">
                <i class="pl-1 pl-md-3 text-secondary text-center small text-muted col-1 fas fa-user-friends"></i>
                {{ fixShortformPlaceholder(data.plural_gender_neutral) }}
                <i
                  v-if="data.is_verified"
                  :class="[containsSearchTerm( [data.plural_masculine, data.plural_feminine, data.plural_gender_neutral] ) ? 'active':'','fas fa-check-circle fa-xs text-success']">
                </i>
                <i
                  v-if="!data.is_verified"
                  :class="[containsSearchTerm( [data.plural_masculine, data.plural_feminine, data.plural_gender_neutral] ) ? 'active':'','fas fa-question-circle fa-xs text-secondary']">
                </i>
              </li>
            </ul>
            <ul class="list-group">
              <li id="example"
                  v-if="data.example"
                  class="list-group-item rounded m-2 m-sm-3 p-1 p-sm-2 border-primary text-secondary small font-italic"
                  style="border-left: 4px solid">
                {{data.example}}
              </li>
            </ul>

          </b-card-body>
          <b-card-footer class="p-0  p-sm-1 table-primary invisible-scrollbar overflow-auto border please-scroll">
            <ul class="list-group list-group-horizontal">
              <li class="list-group-item pb-1 pt-1 small pb-sm-2 pt-sm-2  bg-transparent">
                <router-link :to="{name:'auth.profile', params:{username: data.editor}}">
                  {{ data.editor }}
                </router-link>
              </li>
              <li class="list-group-item pb-1 pt-1 small pb-sm-2 pt-sm-2 caption  bg-transparent">
                {{ data.edit_date }}
              </li>
            </ul>
          </b-card-footer>
        </b-col>
        <b-col id="actions-right"
               class="col-1 col-sm-1 p-0">
          <ul class="list-group center-right">
            <li class="list-group-item bg-transparent"
                v-if="data.add_by_me"
                title="edit"
                v-b-tooltip.hover.left>
              <router-link :to="{name: 'dictionary.entry.edit', params:{id: data.id} }">
                <i class="far fa-edit fa-1x text-primary custom-button"></i>
              </router-link>
            </li>
            <li class="list-group-item bg-transparent">
              <i
                :class='[neutralEntryInfo ? "rotate-pause":"text-success" ," text-success far fa-1x fa-plus-square custom-button"]'
                :title="$t('add alternative')"
                v-b-tooltip.hover.left
                v-on="isLoggedIn() ? { click: () => add(data.id) } : { click: () => showRegisterModal()}"
              >
              </i>
            </li>
            <li class="list-group-item bg-transparent">
              <i v-on="isLoggedIn() ? { click: () => flag(data.id) } : { click: () => showRegisterModal()}"
                 class="far fa-flag fa-1x text-danger custom-button"
                 :title="$t('set flag \'inappropriate or copyright infringement\'')"
                 v-b-tooltip.hover.left
                 v-if="!data.flag_by_me"></i>
              <i @click="flag(data.id)" class="fas fa-flag fa-1x text-danger custom-button active"
                 :title="$t('flagged for \'inappropriate or copyright infringement\'')"
                 v-b-tooltip.hover.left
                 v-if="data.flag_by_me"></i>
            </li>
          </ul>
        </b-col>
      </b-row>
    </b-card>
  </b-col>

  <!--    <b-col class="p-0">-->
  <b-col class="p-0" v-if="!isApiCalled">
    <LoadingPlaceholder
      class="fade-out p-0"></LoadingPlaceholder>
  </b-col>

  <!--register modal-->
  <div>
    <b-modal hide-footer ref="registerModal" v-bind:title="$t('you are not logged in')">
      <div class="d-block text-center">
        <h3>{{$t('wanna create an account?')}}</h3>
      </div>
      <router-link :to="{name: 'auth.register'}" class="mt-2 btn btn-outline-success btn-block">{{$t("Go to register")}}
      </router-link>
      <router-link :to="{name: 'auth.login'}" class="mt-2 btn btn-outline-info btn-block">{{$t("Go to login")}}
      </router-link>
      <b-button block @click="hideRegisterModal" class="mt-3 btn btn-outline-danger">{{$t("Cancel")}}</b-button>
    </b-modal>
  </div>

  <scroll-loader :loader-disable="disableLoadingNextPage" :loader-method="fetchAndSetNextPage">
    <div class="fa-spin">{{$t('🦄')}}</div>
  </scroll-loader>
</b-col>
</template>

<script>
import { DictionaryEntryService } from '../services/dictionaryEntryService'
import LoadingPlaceholder from './LoadingPlaceholder'
import { modalMixin } from '../mixins/modalMixin'
import { dictionaryEntryMixin } from '../mixins/dictionaryEntryMixin'
import { compareMixin } from '../mixins/compareMixin'
import { listMixin } from '../mixins/listMixin'
import { ProfileDictionaryEntryService } from '@/services/profileDictionaryEntryService'

var Vue = require('vue')
export default {
  name: 'List',
  // insert the following code for vue-meta to work
  metaInfo () {
    if (this.$route.params.specific) {
      return {
        title: this.title,
        meta: [
          {
            name: 'description',
            vmid: 'description',
            content: this.description
          },
          {
            property: 'og:title',
            vmid: 'og:title',
            content: this.title
          },
          {
            property: 'og:description',
            vmid: 'og:description',
            content: this.description
          },
          {
            property: 'og:url',
            vmid: 'og:url',
            content: this.canonicalUrl
          },
          {
            name: 'twitter:title',
            vmid: 'twitter:title',
            content: this.title
          },
          {
            name: 'twitter:description',
            vmid: 'twitter:description',
            content: this.description
          }
        ],
        link: [
          { rel: 'canonical', href: this.canonicalUrl },
          { rel: 'alternate', hreflang: 'de', href: this.canonicalUrl }
        ]
      }
    }
  },
  components: {
    LoadingPlaceholder
  },
  data () {
    return {
      userData: {},
      loadedDictionaryEntries: [],
      disableLoadingNextPage: false,
      searchTerm: '', // Hint: When auto completion list is open, the search term is in searchterm.code.c
      nextPageApi: '',
      neutralEntryInfo: '',
      callToAction: '',
      autocompleteloading: false,
      description: '',
      secondTierLoadingDone: false,
      shortformPlaceholder: '',
      canonicalUrl: '',
      page: 1,
      pageSize: 9999,
      autocompletionOptions: [],
      isPaginated: false,
      dictionaryEntriesOrdering: 'top'
    }
  },
  computed: {
    title: function () {
      return `${this.$route.params.specific} ${'| gender app · Alternativen finden & erfassen'.slice(0, 60 - 3 - this.$route.params.specific.length)}${60 - 3 - this.$route.params.specific.length < 45 ? '...' : ''}`
    }
  },
  props: ['filterBy', 'shortformPlaceholderProfile'],
  mixins: [modalMixin, dictionaryEntryMixin, compareMixin, listMixin],
  methods: {
    shortFormLabel (type, shortformPlaceholderKey) {
      if (type === 'neut') return ''
      if (type === 'pair') return 'pair'
      const relevantPlaceholderKey = this.shortformPlaceholderProfile ? this.shortformPlaceholderProfile : shortformPlaceholderKey
      if (!relevantPlaceholderKey.trim()) return 'star'
      return relevantPlaceholderKey
    },
    placeholderForView (shortformPlaceholderKey) {
      const relevantPlaceholderKey = this.shortformPlaceholderProfile ? this.shortformPlaceholderProfile : shortformPlaceholderKey
      let viewable = '*'
      if (relevantPlaceholderKey === 'star') {
        viewable = '*'
      } else if (relevantPlaceholderKey === 'colon') {
        viewable = ':'
      } else if (relevantPlaceholderKey === 'lowdash') {
        viewable = '_'
      } else if (relevantPlaceholderKey === 'slash') {
        viewable = '/-'
      }
      return viewable
    },
    fixShortformPlaceholder (entry) {
      return entry.replace('*', this.placeholderForView(this.shortformPlaceholder))
    },
    getUsername () {
      return Vue.default.cookie.get('username', 'Profil')
    },
    changeDictionaryEntriesOrdering (dictionaryEntriesOrdering) {
      this.dictionaryEntriesOrdering = dictionaryEntriesOrdering
      this.newSearchTerm()
    },
    newSearchTerm (searchTerm, userFilter, dictionaryEntriesOrdering, validated = false) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
      const instance = this
      // interface for displaying cards after search term changes
      // arguments are optional
      // results in the list being shown, the auto completion options being updated and a scroll up event

      // if the search term is already set, no need to reload
      if (searchTerm && (instance.$route.query.search === searchTerm || instance.$route.params.specific === searchTerm)) {
        instance.scrollUp()
        return
      }
      const term = searchTerm || this.$route.params.specific || this.$route.query.search || ''
      const filter = userFilter || this.filterBy || 'all'
      const ordering = dictionaryEntriesOrdering || this.dictionaryEntriesOrdering || 'top'
      this.setUrl(term, validated).then(() => {
        // the set URL is used in fetchAndSetFirstPage()
        // Hint: if term is entry, url is not set, so we don't overwrite existing URLs
        Promise.all(
          [instance.fetchAndSetFirstPage(term, filter, ordering),
            instance.setSearchTerm(term),
            instance.scrollUp()
          ]
        ).then(() => {
          Promise.all(
            [instance.setAutocompletionOptions(term),
              instance.updateUrl(term),
              instance.setDescription(term)
            ]
          )
        }).then(() => {
          this.secondTierLoadingDone = true
          this.$emit('secondTierLoadingDone', true)
        }
        )
      })
    },
    removeDuplicates (myArr, prop) {
      return myArr.filter((obj, pos, arr) => {
        return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos
      })
    },
    fetchAndSetNextPage () {
      // requires fetchAndSetFirstPage to set nextPageApi first
      // Known issue: Will not run if scroll event is not triggered
      const instance = this
      instance.disableLoadingNextPage = !instance.nextPageApi
      if (instance.disableLoadingNextPage) return
      DictionaryEntryService.getPaginatedData(instance.nextPageApi)
        .then((response) => {
          instance.loadedDictionaryEntries = [...instance.loadedDictionaryEntries, ...response.data.results]
          // insert this code to ensure unique dictionary entry in list view
          // if need ordering in the backend is not unambiguous
          // hint: class DictionaryEntryHomeView(BaseModelViewSet): ........order_by('-net_likes', 'pk')
          // const uniqueArray = this.removeDuplicates(newArray, 'id')
          // console.log(instance.loadedDictionaryEntries)
          // instance.loadedDictionaryEntries = [...uniqueArray]
          instance.nextPageApi = response.data.next
          // Stop scroll loading...
          instance.disableLoadingNextPage = response.data.remaining_count <= 0
        })
    },
    async setAutocompletionOptions (searchArg) {
      // searchElement.removeAttribute('placeholder')
      // we want to set the auto completion options according to the search term.
      // searchArg is optional
      // results in the auto completion options according to the search term
      // comes from the backend: PAGE_SIZE cards where the search term is included with the most net_likes
      // is sorted in frontend according to the similarity to the search term
      // const instance = this
      // Hint: The auto completion isn't the same as the displayed cards. e.g. we don't provide infinite scroll
      const instance = this
      this.autocompletionOptions = []
      instance.autocompleteloading = true
      this.$forceUpdate()
      const search = searchArg || (typeof (this.searchTerm) === 'string' ? this.searchTerm : '') || this.searchTerm.code.c || ''
      DictionaryEntryService.getAutoCompletionData(search.trim()).then((response) => {
        const datasets = response.data.results
        const unsorted = []
        for (const data of datasets) {
          const _option = this.prepareAutoCompleteOptions(data)
          unsorted.push(..._option)
        }
        const unique = []
        const map = new Map()
        for (const item of unsorted) {
          if (!map.has(item.code.c)) {
            map.set(item.code.c, true) // set any value to Map
            unique.push({
              code: item.code,
              label: item.label,
              id: item.id
            })
          }
        }
        this.autocompletionOptions = unique.sort(function (a, b) {
          // return 0
          // words similar to the search words will show up un top of the search.
          if (!a.code || !b.code || !search) return 0
          if (!a.code.c || !b.code.c) return 0
          return instance.jaroWinkerStringSimilarity(b.code.c, search) - instance.jaroWinkerStringSimilarity(a.code.c, search)
        })
      }).then(() => {
        instance.autocompleteloading = false
        this.$forceUpdate()
      }
      ).then(() => {
        if (!search) {
          const searchElement = document.getElementsByClassName('vs__search')[0]
          searchElement.setAttribute('placeholder', this.$t('search...'))
        }
      })
    },
    //  for the other methods, check relevant mixins
    setDescription (searchTerm) {
      let _canonicalUrl = 'https://genderapp.org/'
      let _description = 'Geschlechtergerecht:'
      if (this.loadedDictionaryEntries.length > 0) {
        const topAnswer = this.loadedDictionaryEntries[0]
        _canonicalUrl += 'dict/'
        const canonicalCandidates = [topAnswer.singular_masculine, topAnswer.term, topAnswer.plural_masculine, topAnswer.singular_feminine, topAnswer.plural_feminine]
        const match = canonicalCandidates.find(el => el === searchTerm)
        if (match) {
          this.canonicalUrl = encodeURI(_canonicalUrl + canonicalCandidates.find(el => el))
        }
        if ((searchTerm === topAnswer.plural_masculine || searchTerm === topAnswer.plural_feminine) && topAnswer.plural_gender_neutral) {
          _description += ` '${topAnswer.plural_gender_neutral}';`
        } else if ((searchTerm === topAnswer.singular_masculine || searchTerm === topAnswer.singular_feminine) && topAnswer.singular_gender_neutral) {
          _description += ` '${topAnswer.singular_gender_neutral}';`
        } else if ((searchTerm === topAnswer.term) && topAnswer.alternative) {
          _description += ` '${topAnswer.alternative}';`
        }
      }
      if (this.loadedDictionaryEntries.length > 1) {
        const secondAnswer = this.loadedDictionaryEntries[1]
        if ((searchTerm === secondAnswer.plural_masculine || searchTerm === secondAnswer.plural_feminine) && secondAnswer.plural_gender_neutral) {
          _description += ` '${secondAnswer.plural_gender_neutral}';`
        } else if ((searchTerm === secondAnswer.singular_masculine || searchTerm === secondAnswer.singular_feminine) && secondAnswer.singular_gender_neutral) {
          _description += ` '${secondAnswer.singular_gender_neutral}';`
        } else if ((searchTerm === secondAnswer.term) && secondAnswer.alternative) {
          _description += ` '${secondAnswer.alternative}';`
        }
      }
      if (_description === 'Geschlechtergerecht:') {
        this.description = `Geschlechtergerechte Alternativen für '${searchTerm}' im demokratischen Wörterbuch von gender app 🤹`
        return this.description
      } else {
        _description += ' ... gender app · Gendern mit demokratischem Wörterbuch und Office Add-ins 🤹'
      }
      this.description = _description
      return _description
    },
    async getShortformPlaceholder () {
      if (!this.getUsername()) return
      await ProfileDictionaryEntryService.getProfileDictionaryEntryStatistics(this.getUsername())
        .then((response) => {
          this.shortformPlaceholder = response.data.shortform_placeholder
        }).catch((error) => {
          console.log(error)
        })
    }
  },
  created () {
  },
  mounted () {
    this.getShortformPlaceholder()
    // Hint: url is read in 'newSearchTerm'. That way we make sure that every 'mounted' the cards are reloaded
    this.newSearchTerm('', '', '')
    // Hint: autocompletion is already update in newSearchTerm, so no need to do it here.
  },
  watch: {
    $route (to, from) {
      this.newSearchTerm('', '', '')
    },
    filterBy: function () {
      this.newSearchTerm('', this.filterBy, 'top')
    }
  }
}
</script>

<style scoped>

  .card-body {
    padding: 0.5rem;
  }

  .list-group-item {
    border: transparent;
  }

  /*.invisible-scrollbar {*/
  /*  scrollbar-width: none;*/
  /*}*/

  .invisible-scrollbar::-webkit-scrollbar {
    /*Chrome*/
    display: none !important;
  }
  .invisible-scrollbar {
  /*FireFox*/
  scrollbar-width: none !important;
  /*IE10+*/
  -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .please-scroll {
    white-space: nowrap;
    overflow: hidden;
    overflow-x: scroll;
  }

  .center-left {
    position: absolute;
    top: 50%;
    left: 60%;
    transform: translate(-50%, -50%);
  }

  .center-right {
    font-size: larger;
    position: absolute;
    top: 50%;
    left: 40%;
    transform: translate(-50%, -50%);
  }

@keyframes shake-for-attention {
    11% {
        transform: rotate(90deg);
    }
    22% {
        transform: rotate(0deg);
    }}

  .rotate-text {
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    margin: 0;
    -webkit-animation: spin 0.2s linear infinite;
    -moz-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }

  .rotate-pause {
    -webkit-animation: shake-for-attention 5s linear infinite;
    -moz-animation: shake-for-attention 5s linear infinite;
    animation: shake-for-attention 5s linear infinite;
  }

  @-moz-keyframes spin {
    100% {
      -moz-transform: rotateY(360deg);
    }
  }

  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotateY(360deg);
    }
  }

  @keyframes spin {
    100% {
      -webkit-transform: rotateY(360deg);
      transform: rotateY(360deg);
    }
  }
  .gender_card_muted {
    background-color: #c6d4e2;
  }

  .gender_card:hover {
    -webkit-filter: brightness(1.02);
    -moz-filter: brightness(1.02);
    -ms-filter: brightness(1.02);
    filter: brightness(1.02);
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  }

  .gender_card:focus {
    -webkit-filter: brightness(1.02);
    -moz-filter: brightness(1.02);
    -ms-filter: brightness(1.02);
    filter: brightness(1.02);
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  }

  .gender_card:active {
    -webkit-filter: brightness(1.02);
    -moz-filter: brightness(1.02);
    -ms-filter: brightness(1.02);
    filter: brightness(1.02);
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  }

  .fade-out {
    animation: Pulsate 4s linear infinite;
  }

  @keyframes Pulsate {
    from {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
</style>
