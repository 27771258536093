// define a mixin object
var Vue = require('vue')
export const modalMixin = {
  methods: {
    isLoggedIn () {
      const token = Vue.default.cookie.get('token')
      const username = Vue.default.cookie.get('username')
      return !!token && !!username
    },
    showRegisterModal () {
      this.$refs.registerModal.show()
    },
    hideRegisterModal () {
      this.$refs.registerModal.hide()
    }
  }
}
