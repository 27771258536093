<template>
  <b-col class="">
    <b-col :key="data" class="mt-1 mt-md-4 p-1" v-for="data in placeholders">
      <b-card
        border-variant="secondary"
        class="p-0 gender_card"
        header-bg-variant="primary"
      >
        <b-row>
          <b-col id="actions-left"
            class="col-1  col-sm-1 p-0">
            <ul class="list-group  center-left">
              <li class="list-group-item p-0 bg-transparent">
                <i class="fa fa-angle-up  fa-2x text-success custom-button"></i>
              </li>
              <li class="list-group-item m-0 p-0 text-center bg-transparent"
              style="min-height: 50px">
              </li>
              <li class="list-group-item p-0 bg-transparent">
                <i class="fa fa-angle-down fa-2x text-secondary custom-button text-muted"></i>
              </li>
            </ul>
          </b-col>
          <b-col id="content"
            class="col-10 col-sm-10 p-2">
            <b-card-header
              class="p-0  p-sm-1 table-primary invisible-scrollbar overflow-auto border please-scroll">
              <ul class="list-group list-group-horizontal">
                <li
                  class="btn btn-secondary small p-1 p-sm-2 m-1 m-sm-2"
                style="min-width: 100px">
                  <span class="spellchecker-underline">
                  </span>
                </li>
                <li
                  class="btn btn-secondary small p-1 p-sm-2 m-1 m-sm-2"
                style="min-width: 100px">
                  <span class=" spellchecker-underline">
                  </span>
                </li>
                <li
                  class="btn btn-secondary small p-1 p-sm-2 m-1 m-sm-2"
                style="min-width: 100px"
                >
                  <span class="spellchecker-underline">
                  </span>
                </li>
                <li
                  class="btn btn-secondary small p-1 p-sm-2 m-1 m-sm-2"
                style="min-width: 100px"
                >
                  <span class="spellchecker-underline">
                  </span>
                </li>
              </ul>
            </b-card-header>
            <b-card-body
              class="p-1 p-md-2">
              <ul class="table p-0 m-0">
                <li id="singular_gender_neutral"
                  class="invisible-scrollbar please-scroll p-1 p-sm-2"
                >
                </li>
              </ul>
              <ul class="list-group">
                <li id="example"
                    class="list-group-item rounded m-2 m-sm-3 p-1 p-sm-2 border-primary text-secondary small font-italic"
                    style="border-left: 4px solid;
                           min-height: 50px;">
                </li>
            </ul>

            </b-card-body>
            <b-card-footer class="p-0  p-sm-1 table-primary invisible-scrollbar overflow-auto border please-scroll"
            style="min-height: 50px">
              <ul class="list-group list-group-horizontal">
                <li class="list-group-item pb-1 pt-1 small pb-sm-2 pt-sm-2  bg-transparent">

                </li>
                <li class="list-group-item pb-1 pt-1 small pb-sm-2 pt-sm-2 caption  bg-transparent">

                </li>
              </ul>
            </b-card-footer>
          </b-col>
          <b-col id="actions-right"
            class="col-1 col-sm-1  p-0">
            <ul class="list-group center-right">
              <li class="list-group-item bg-transparent">
                <i
                  class="far fa-plus-square text-success"
                >
                </i>
              </li>
              <li class="list-group-item bg-transparent">
                <i class="far fa-flag fa-1x text-danger custom-button"></i>
              </li>
            </ul>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
</b-col>
</template>

<script>

export default {
  name: 'LoadingPlaceholder',
  data () {
    return {
      placeholders: ['1', '2', '3']
    }
  },
  methods: {
  },
  created () {
  },
  mounted () {
  },
  watch: {
  }
}
</script>

<style scoped>

  .card-body {
    padding: 0.5rem;
  }

  .list-group-item {
    border: transparent;
  }

  .invisible-scrollbar::-webkit-scrollbar {
    display: none !important;
  }
  .invisible-scrollbar {
  /*FireFox*/
  scrollbar-width: none !important;
  /*IE10+*/
  -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .please-scroll {
    white-space: nowrap;
    overflow: hidden;
    overflow-x:scroll;
  scrollbar-width: none !important;
  }

  .center-left {
    position: absolute;
    top: 50%;
    left: 60%;
    transform: translate(-50%, -50%);
  }

  .center-right {
    font-size: larger;
    position: absolute;
    top: 50%;
    left: 40%;
    transform: translate(-50%, -50%);
  }

  .rotate-text {
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    margin: 0;
    -webkit-animation: spin 0.2s linear infinite;
    -moz-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }

  @-moz-keyframes spin {
    100% {
      -moz-transform: rotateY(360deg);
    }
  }

  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotateY(360deg);
    }
  }

  @keyframes spin {
    100% {
      -webkit-transform: rotateY(360deg);
      transform: rotateY(360deg);
    }
  }

  .gender_card:hover{
    -webkit-filter: brightness(1.02);
    -moz-filter: brightness(1.02);
    -ms-filter: brightness(1.02);
    filter: brightness(1.02);
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);
-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);
box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);
  }
  .gender_card:focus{
    -webkit-filter: brightness(1.02);
    -moz-filter: brightness(1.02);
    -ms-filter: brightness(1.02);
    filter: brightness(1.02);
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);
-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);
box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);
  }
  .gender_card:active{
    -webkit-filter: brightness(1.02);
    -moz-filter: brightness(1.02);
    -ms-filter: brightness(1.02);
    filter: brightness(1.02);
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);
-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);
box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);
  }

</style>
